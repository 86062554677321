import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static targets = ["menu"];

  toggleMenu() {
    this.menuTarget.classList.toggle('hidden');
  }

  copyLink() {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  }
}
