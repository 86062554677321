import BrandCarsController from "./brand_cars_controller";

export default class extends BrandCarsController {
    static targets = [
        'modal',
        'sortMenu',
        'selectedSort',
        'modelMenu',
        'selectedModel',
        'minYearMenu',
        'selectedMinYear',
        'maxYearMenu',
        'selectedMaxYear',
        'frame',
        'placeholder',
        'cars'
    ];

    openModal() {
        console.log('Opening modal')
        this.modalTarget.classList.remove("hidden");
        document.body.classList.add("modal-open")
    }

    closeModal() {
        this.modalTarget.classList.add("hidden");
        document.body.classList.remove("modal-open")


    }
}