import BrandCarsController from "./brand_cars_controller";

export default class extends BrandCarsController {
    static targets = [
        'sortMenu',
        'selectedSort',
        'modelMenu',
        'selectedModel',
        'minYearMenu',
        'selectedMinYear',
        'maxYearMenu',
        'selectedMaxYear',
        'frame',
        'placeholder',
        'cars',
    ];
}