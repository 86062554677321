import {Controller} from "@hotwired/stimulus";
import Rails from "@rails/ujs";

let menuTargets = ['brand', 'model']
export default class extends Controller {
    static targets = ['carId', 'modal', 'brandMenu', 'modelMenu', 'selectedBrand','selectedBrandId', 'selectedModel', 'modelButton', 'doneButton', 'errorMessage']

    connect(){
        this.modalBackdrop = document.getElementById('modal-backdrop');
        if (!this.hasModalBackdropListener) {
            this.modalBackdrop.addEventListener('click', event => {
                if (event.target === this.modalBackdrop) {

                    this.exitModal();
                }
            });
            this.hasModalBackdropListener = true;
        }
    }
    openModal() {
        this.modalTarget.classList.remove("hidden");
        document.body.classList.add("modal-open")
        this.modalBackdrop.style.display = 'flex'; // Show the backdrop



    }

    exitModal(){
        this.modalTarget.classList.add("hidden");
        document.body.classList.remove("modal-open")
        this.modalBackdrop.style.display = 'none'; // Hide the backdrop


    }

    closeModal(event) {
        event.preventDefault()
        this.exitModal()

        const model = this.selectedModelTarget.textContent.trim();
        const brandId = this.selectedBrandIdTarget.value;
        const carId = this.carIdTarget.value;

        if (document.querySelector(`[data-model-name="${model}"]`)) {
            return; // Exit if model exists
        }

        Rails.ajax({
            url: `/cars/${carId}/add_model`,
            type: "GET",
            dataType: "html",
            data: new URLSearchParams({ model: model, brand_id: brandId }).toString(),
            success: (data) => {
                console.log(data.body)
            },
            error: (err) => {
                console.error("Failed to load new model cars:", err);
            }
        });
    }

    toggleBrand() {
        this.toggle('brand');
    }

    toggleModel() {
        this.toggle('model');
    }


    toggle(targetName) {
        this.hideAllMenus(targetName); // Hide all menus
        const menu = this[`${targetName}MenuTarget`];
        menu.classList.toggle("hidden");
    }

    hideAllMenus(selectedMenu) {
        const allMenus = menuTargets.filter(menu => menu !== selectedMenu); // Add all menu names here
        allMenus.forEach(menuName => {
            const menu = this[`${menuName}MenuTarget`];
            menu.classList.add("hidden");
        });
    }

    changeSelected(event, targetName, menuName) {
        event.preventDefault(); // Prevent the default link behavior
        const selectedItem = event.currentTarget; // Get the clicked item
        const selectedText = selectedItem.innerText; // Get the inner text of the clicked item

        // Log the selected text and set it as the new selected value
        this[`${targetName}Target`].textContent = selectedText;

        this.toggle(menuName); // Hide the dropdown menu
    }

    changeSelectedBrand(event) {
        this.changeSelected(event, 'selectedBrand', 'brand')
        this.clearModelDropdown()
        this.hideErrorMessage();
        const brandElement = event.currentTarget;
        const selectedBrandId = brandElement.getAttribute("data-brand-id");
        const selectedBrandName = brandElement.textContent.trim();
        this.selectedBrandIdTarget.value = selectedBrandId;


        // Send AJAX request to fetch models for the selected brand
        Rails.ajax({
            url: `/brands/${selectedBrandId}/models`,
            type: "GET",
            dataType: "json",
            success: (data) => {
                this.updateModelDropdown(data.models);
                // Update the selected model to the first model of the new brand
                this.enableModelButton();
                this.disableDoneButton();

            },
            error: (err) => {
                console.error("Failed to fetch models:", err);
                this.showErrorMessage();
                // Re-enable the models dropdown in case of error
                // this.enableModelButton();

            }
        });

        // Optionally, update the selected brand display or other UI elements
        this.selectedBrandTarget.textContent = selectedBrandName;
    }

    updateSelectedModelText(selectedText) {
        // Directly update the selected model text without toggling the menu
        this.selectedModelTarget.textContent = selectedText;
    }

    clearModelDropdown(){
        const modelMenu = this.modelMenuTarget;
        modelMenu.innerHTML = ""; // Clear existing models
        this.selectedModelTarget.textContent = 'Select Model'
    }
    updateModelDropdown(models) {
        const modelMenu = this.modelMenuTarget;

        models.forEach((model, index) => {
            const firstItemClass = index === 0 ? 'rounded-t-md' : '';
            const lastItemClass = index === models.length - 1 ? 'rounded-b-md' : '';

            const modelItem = document.createElement("a");
            modelItem.href = "#";
            modelItem.setAttribute("data-action", "click->comparisonModal#changeSelectedModel");
            modelItem.className = `text-white dropdown block px-4 py-2 text-[16px] ${firstItemClass} ${lastItemClass}`;
            modelItem.setAttribute("role", "menuitem");
            modelItem.setAttribute("tabindex", "-1");
            modelItem.textContent = model;

            modelMenu.appendChild(modelItem);
        });
    }


    enableModelButton() {
        this.modelButtonTarget.removeAttribute('disabled');
        this.modelButtonTarget.classList.remove('dropdown-disabled')

    }

    enableDoneButton(){
        this.doneButtonTarget.removeAttribute('disabled');
        this.doneButtonTarget.classList.remove('done-disabled')
    }

    disableDoneButton(){
        this.doneButtonTarget.setAttribute('disabled', 'disabled');
        this.doneButtonTarget.classList.add('done-disabled')
    }

    showErrorMessage() {
        this.errorMessageTarget.classList.remove('hidden');
    }

    hideErrorMessage() {
        this.errorMessageTarget.classList.add('hidden');
    }

        changeSelectedModel(event) {
        this.changeSelected(event, 'selectedModel', 'model');
        if (event.currentTarget.innerText !== 'Select Model'){
            this.enableDoneButton();
        }

    }

}