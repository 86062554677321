import { Controller } from "@hotwired/stimulus";

let menuTargets = ['sort', 'minYear', 'maxYear']

export default class extends Controller {
    static targets = ['brand', 'canonicalUrl']

    connect() {
        if (this.hasModelTarget) {
            menuTargets.push('model')
        }
        window.addEventListener("popstate", function() {
            window.location = location.href;
        });

        document.addEventListener("turbo:frame-render", (event) => {
            console.log('Frame Rendering')
            console.log(event)
            const existingCanonicalLink = document.querySelector('link[rel="canonical"]')
            const canonicalUrl = this.canonicalUrlTarget.value
            const newCanonicalUrl = `${window.location.origin}${canonicalUrl}`;

                if (existingCanonicalLink) {
                    // If a canonical link tag already exists, update its "href" attribute
                    existingCanonicalLink.href = newCanonicalUrl;
                } else {
                    // If a canonical link tag doesn't exist, create a new one and add it to the head
                    const canonicalLink = document.createElement('link');
                    canonicalLink.rel = 'canonical';
                    canonicalLink.href = newCanonicalUrl;
                    document.head.appendChild(canonicalLink);
                }
        });
    }


    changeSource(value, filter){
        const scrollToPosition = 100;  // You can adjust this value based on your needs

        window.scrollTo({top: scrollToPosition, behavior: 'smooth'});
        setTimeout(() => {
            const current_source = this.frameTarget.src;
            // this.frameTarget.src  = `${current_source}?model=${value}`

            const currentURL = window.location.href;
            const new_url = this.replaceUrlParam(currentURL, filter, encodeURIComponent(value));
            const urlObject = this.replaceUrlParam(new_url, 'replace', 'true');

            // Construct the updated URL without the origin
            const updatedURL = urlObject.replace(window.location.origin, "");

            // Update the URL in the browser without triggering a full page reload
            this.frameTarget.src = updatedURL.toString();
            this.placeholderTarget.classList.remove('hidden');
            this.carsTarget.classList.add('hidden');
            // this.paginationTarget.classList.add('hidden');

            // Optionally, update the browser's URL
            window.history.pushState({}, '', `${new_url}`);

        }, 500);


    }


    hideAllMenus(selectedMenu) {
        const allMenus = menuTargets.filter(menu => menu !== selectedMenu); // Add all menu names here
        allMenus.forEach(menuName => {
            const menu = this[`${menuName}MenuTarget`];
            menu.classList.add("hidden");
        });
    }

    toggle(targetName) {
        this.hideAllMenus(targetName); // Hide all menus
        const menu = this[`${targetName}MenuTarget`];
        menu.classList.toggle("hidden");
    }

    changeSelected(event, targetName, menuName) {
        event.preventDefault(); // Prevent the default link behavior
        const selectedItem = event.currentTarget; // Get the clicked item
        const selectedText = selectedItem.innerText; // Get the inner text of the clicked item

        // Log the selected text and set it as the new selected value
        this[`${targetName}Target`].textContent = selectedText;

        this.toggle(menuName); // Hide the dropdown menu
        const filterName = this.camelToSnakeCase(menuName);
        this.changeSource(selectedText, filterName);
    }
    toggleSort() {
        this.toggle('sort');
    }

    changeSelectedSort(event) {
        this.changeSelected(event, 'selectedSort', 'sort');
    }

    toggleModel() {
        console.log('toggling model')
        this.toggle('model');
    }

    changeSelectedModel(event) {
        this.changeSelected(event, 'selectedModel', 'model');
    }

    toggleMinYear() {
        this.toggle('minYear');
    }

    changeSelectedMinYear(event) {
        this.changeSelected(event, 'selectedMinYear', 'minYear');
    }

    toggleMaxYear() {
        this.toggle('maxYear');
    }

    changeSelectedMaxYear(event) {
        this.changeSelected(event, 'selectedMaxYear', 'maxYear');
    }


    camelToSnakeCase(str) {
        return str.replace(/([A-Z])/g, '_$1').toLowerCase();
    }

    replaceUrlParam(url, paramName, paramValue)
    {
        if (paramValue == null) {
            paramValue = '';
        }
        var pattern = new RegExp('\\b('+paramName+'=).*?(&|#|$)');
        if (url.search(pattern)>=0) {
            return url.replace(pattern,'$1' + paramValue + '$2');
        }
        url = url.replace(/[?#]$/,'');
        return url + (url.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue;
    }
}