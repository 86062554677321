import { Controller } from "@hotwired/stimulus";


export default class extends Controller {

    static targets = [
        'frame',
        'carName',
        'imageContainer', // Add a target for the image container,
        'getPhotosButton',
        'buttonText',
        'photosSpinner'

    ]

    connect(){
        let tooltipContainer = document.querySelector('.tooltip-container');

        // Check if the tooltip container exists
        if (tooltipContainer) {
            tooltipContainer.addEventListener('click', function() {
                // Try to find any tooltip within the container
                var tooltip = this.querySelector('.warning-tooltip') || this.querySelector('.alert-tooltip');

                // If a tooltip is found, toggle its visibility and opacity
                if (tooltip) {
                    tooltip.style.visibility = tooltip.style.visibility === 'visible' ? 'hidden' : 'visible';
                    tooltip.style.opacity = tooltip.style.opacity === '1' ? '0' : '1';
                }
            });
        }
    }


    getPhotos() {
        this.photosSpinnerTarget.classList.remove('hidden');
        this.buttonTextTarget.classList.add('hidden');
        this.getPhotosButtonTarget.disabled = true;

        const carName = this.carNameTarget.value; 
        // console.log(carName);
        const validImages = [];

    
        // const data = ['https://media.carsandbids.com/cdn-cgi/image/width=…tos/304P0k8g-0EpQpdOBJD-(edit).jpg?t=168980639090', 'https://www.netcarshow.com/BMW-135i_Coupe-2008-Interior.53c1e9f0.jpg', 'https://media.carsandbids.com/cdn-cgi/image/width=…tos/3LkWpElW-6X_gEDDbQA-(edit).jpg?t=171436522445', 'https://i.imgur.com/mXGvnAC.jpg', 'https://lookaside.fbsbx.com/lookaside/crawler/media/?media_id=2220566761619206', 'https://www.edmunds.com/assets/m/for-sale/99-wbauc73538vf24069/img-1-600x400.jpg', 'https://www.motortrend.com/uploads/sites/11/2010/11/2010-BMW-128i-coupe-side-view-driver1.jpg', 'https://www.1addicts.com/forums/attachment.php?attachmentid=396912&stc=1&d=1276396648', 'https://media.carsandbids.com/cdn-cgi/image/width=…tos/KDW8JNER-AZbh8RwEPz-(edit).jpg?t=172416005596', 'https://www.1addicts.com/forums/attachment.php?attachmentid=3101118&stc=1&d=1675957184']

        fetch(`/api/car_images?name=${carName}`)
          .then(response => response.json())
          .then(data => {
            console.log(data); // Log the array of image links

            // Clear previous images
            this.imageContainerTarget.innerHTML = '';

            const loadImagesPromises = data.map(imageUrl => {
                return new Promise((resolve) => {
                  const img = new Image();
                  img.src = imageUrl;
            
                  // If the image loads, add it to validImages
                  img.onload = () => {
                    validImages.push(imageUrl);
                    resolve();
                  };
            
                  // If there's an error, we still resolve the promise, but don't add the URL
                  img.onerror = () => {
                    console.log(`Broken image URL skipped: ${imageUrl}`);
                    resolve();
                  };
                });
              });
    
              Promise.all(loadImagesPromises).then(() => {
                // Render the valid images after loading
                this.renderImages(validImages);
                this.hideSpinner();
                this.photosSpinnerTarget.classList.add('hidden');
                this.getPhotosButtonTarget.classList.add('hidden');
    
                this.buttonTextTarget.classList.remove('hidden');
                this.getPhotosButtonTarget.disabled = false;
                if (validImages.length === 0) {
                  console.log("No valid images found.");
                }
              });

            // Append each image to the container
            // data.forEach(imageUrl => {

            //     const img = new Image();
            //     img.src = imageUrl;
        
            //     // If the image loads successfully, add to validImages array
            //     img.onload = () => {
            //       validImages.push(imageUrl);
        
            //       // Only render images once all checks are complete
            //       if (validImages.length === data.length) {
            //         this.renderImages(validImages);
            //         this.hideSpinner();
            //       }
            //     };

            // });
            
            console.log(validImages)
        
          })
          .catch(error => {
            console.error("Error fetching car images:", error);
          });

        // const data = ['https://media.carsandbids.com/cdn-cgi/image/width=…tos/304P0k8g-0EpQpdOBJD-(edit).jpg?t=168980639090', 'https://www.netcarshow.com/BMW-135i_Coupe-2008-Interior.53c1e9f0.jpg', 'https://media.carsandbids.com/cdn-cgi/image/width=…tos/3LkWpElW-6X_gEDDbQA-(edit).jpg?t=171436522445', 'https://i.imgur.com/mXGvnAC.jpg', 'https://lookaside.fbsbx.com/lookaside/crawler/media/?media_id=2220566761619206', 'https://www.edmunds.com/assets/m/for-sale/99-wbauc73538vf24069/img-1-600x400.jpg', 'https://www.motortrend.com/uploads/sites/11/2010/11/2010-BMW-128i-coupe-side-view-driver1.jpg', 'https://www.1addicts.com/forums/attachment.php?attachmentid=396912&stc=1&d=1276396648', 'https://media.carsandbids.com/cdn-cgi/image/width=…tos/KDW8JNER-AZbh8RwEPz-(edit).jpg?t=172416005596', 'https://www.1addicts.com/forums/attachment.php?attachmentid=3101118&stc=1&d=1675957184']

        //         data.forEach(imageUrl => {
        //         const img = document.createElement('img'); // Create an img element
        //         img.src = imageUrl; // Set the source to the image URL
        //         img.alt = 'Car Image'; // Optional: Add alt text
        //         img.style.width = '300px'; // Optional: Style the image
        //         img.style.height = 'auto'; // Optional: Maintain aspect ratio
        //         this.imageContainerTarget.appendChild(img); // Append the image to the container
        //     });

        //     // Hide spinner, enable button, show button text again
        //     this.photosSpinnerTarget.classList.add('hidden');
        //     this.getPhotosButtonTarget.classList.add('hidden');

        //     this.buttonTextTarget.classList.remove('hidden');
        //     this.getPhotosButtonTarget.disabled = false;
    }


    renderImages(images) {
        // Clear previous images
        this.imageContainerTarget.innerHTML = '';
      
        // Append each valid image to the container
        images.forEach(imageUrl => {
          const img = document.createElement('img');
          img.src = imageUrl;
          img.alt = 'Car Image';
          img.style.width = '300px';
          img.style.height = 'auto';
          img.classList.add("rounded", "shadow-md", "w-full", "h-auto"); // Optional: Tailwind classes
      
          this.imageContainerTarget.appendChild(img);
        });
      
        // Hide spinner and reset button states
        this.photosSpinnerTarget.classList.add('hidden');
        this.getPhotosButtonTarget.classList.add('hidden');
        this.buttonTextTarget.classList.remove('hidden');
        this.getPhotosButtonTarget.disabled = false;
      }

    openCarPhotoModal(imageUrl) {
        const carPhotoModal = document.getElementById('carPhotoModal');
        const modalImage = document.getElementById('modalImage');
        modalImage.src = imageUrl; // Set the src of the modal image
        carPhotoModal.classList.remove('hidden'); // Show the modal
    }

    closeModal() {
        const carPhotoModal = document.getElementById('carPhotoModal');
        carPhotoModal.classList.add('hidden'); // Hide the modal
    }

      
    load(event) {
        event.preventDefault();
        const url = event.currentTarget.dataset.turboUrl;
        const frameId = this.frameTarget.id;
        document.getElementById(frameId).src = url;
    }

    loadWithPlaceholder(event) {
        event.preventDefault();
        const url = event.currentTarget.dataset.turboUrl;
        const frameId = event.currentTarget.closest("turbo-frame").id;

        // Render placeholders
        const frame = document.getElementById(frameId);
        frame.innerHTML = `
      <div class="spinner-container">
        <div class="spinner"></div>
      </div>
    `;


        // Load actual content
        frame.src = url;
    }
    handleGeneration() {
        const carId = this.carIdTarget.value
        const requestType = 'General'
        this.addResponseContainer(requestType)
        this.streamOpenAiResponse(carId, requestType)
        this.generateContainerTarget.classList.add('hidden')
        this.buttonContainerTextTarget.classList.add('hidden')
    }

    handleButtonClick(event) {
        const button = event.currentTarget

        button.classList.add('hidden')
        this.toggleButtonContainer()
        this.infoContainerTarget.insertAdjacentHTML('beforeend', `
            <div class="clicked-text-container my-4">
              <div class="clicked-text">
                ${button.innerText}
              </div>
            </div>
          `.trim())
       const requestString=  this.getRequestString(button.innerText)
        this.loadOpenAiResponse(requestString)
    }


    streamOpenAiResponse(carId, requestType) {
        const responseContainer = document.getElementById(`response-content-${requestType}`);

        // Define a function to setup event listeners
        const setupEventListeners = (source) => {
            source.onopen = (event) => {
                console.log("The connection has been established.", event);
            };
            source.onmessage = (event) => {
                console.log("EventSource message received:", event.data);
                const eventData = JSON.parse(event.data);
                console.log(eventData);

                if (eventData.finished) {
                    this.toggleButtonContainer();
                    source.close();
                } else {
                    responseContainer.insertAdjacentHTML('beforeend', eventData.name.replace(/\n/g, '<br />'));
                }
            };
            source.onerror = (err) => {
                console.log('EventSource error:', err);
                source.close();
                this.toggleButtonContainer();
                // Assuming err.data might not be the correct way to access the error message.
                // It might depend on the actual error object structure.
                // const error = JSON.parse(err.data).name;
                // responseContainer.insertAdjacentHTML('beforeend', error);
            };
        };

        if (requestType === 'General') {
            // responseContainer.insertAdjacentHTML('beforeend', 'Generating...');
            
            setTimeout(() => {
                const source = new EventSource(`${window.location.origin}/stream_data?car_id=${carId}&request_type=${requestType}`);
                setupEventListeners(source);
            }, 0);
        } else {
            const source = new EventSource(`${window.location.origin}/stream_data?car_id=${carId}&request_type=${requestType}`);
            setupEventListeners(source);
        }
    }

    addResponseContainer(requestType) {
        this.infoContainerTarget.insertAdjacentHTML('beforeend', `
          <div class="px-4">
            <div class="text-gray-300 mt-2 lg:text-[18px] text-[16px]">
              <span id="response-content-${requestType}"></span>
            </div>
          </div>
        `.trim());
    }

    loadOpenAiResponse(requestType) {
        this.addResponseContainer(requestType)
        const carId = this.carIdTarget.value
        fetch(`${window.location.origin}/openai_response?car_id=${carId}&request_type=${requestType}`)
            .then(response => {
                if (!response.ok) {
                    // Handle non-OK responses (e.g., 404, 500, etc.)
                    throw new Error('Network response was not ok');
                } else {
                    // Parse the response as JSON and return it
                    return response.json();
                }
            })
            .then(data => {
                // Handle the data returned from the endpoint
                if (data !== null) {
                    this.appendOpenAiResponse(data.data, requestType)
                } else {
                   this.streamOpenAiResponse(carId, requestType)
                }
            })
            .catch(error => {
                this.appendOpenAiResponse(error)

                // setChatText('An error occurred while fetching data.');
            });
    }

    async appendOpenAiResponse(data, requestType) {
        const paragraphs = data.split(/\n/);

        for (const paragraph of paragraphs) {


            const typingAnimationElement = document.getElementById(`response-content-${requestType}`);
            let currentIndex = 0;
            while (currentIndex < paragraph.length) {
                const chunkSize = Math.floor(Math.random() * 3) + 1; // Random chunk size between 1 and 5
                const chunk = paragraph.substr(currentIndex, chunkSize);

                typingAnimationElement.insertAdjacentHTML('beforeend', chunk); // Render the entire chunk
                currentIndex += chunkSize;

                if (currentIndex % 10 === 0 && Math.random() < 0.25) {
                    const randomDelay = Math.floor(Math.random() * 201);
                    await new Promise(resolve => setTimeout(resolve, randomDelay));
                } else {
                    await new Promise(resolve => setTimeout(resolve, 3)); // Adjust the default delay as needed
                }
            }


            // Add a line break after the paragraph is typed
            typingAnimationElement.insertAdjacentHTML('beforeend', `<br />`);
        }

        this.toggleButtonContainer()
    }

    toggleButtonContainer() {
        console.log('toggle')
        if (this.buttonContainerTarget.classList.contains('hidden')) {
            this.buttonContainerTarget.classList.remove('hidden')
        } else {
            this.buttonContainerTarget.classList.add('hidden')
        }
    }


    getRequestString(buttonText) {
        switch (buttonText) {
            case 'Tell me some interesting facts':
                return 'Facts'
                break
            case 'How does it stack up against its competitors?':
                return 'Competitors'
            case 'What are some common problems?':
                return 'Problems'
            case 'How much does it cost used?':
                return 'Pricing'
        }
    }

}