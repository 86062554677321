import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["engine"];


    convertFormat() {
        let engineValue = this.engineTarget.value;

        // First, normalize "+ Elec" to "+ Electric" and remove it temporarily from the main string
        let electricPattern = /\+\s*Elec\b/;
        let hasElectric = engineValue.match(electricPattern);
        engineValue = engineValue.replace(electricPattern, '');

        // Handle "Twin-Turbo" first to avoid it being affected by the next replacement
        engineValue = engineValue.replace(/Twin-Turbo/g, 'Twin-Turbocharger');

        // Handle "Turbo" only if "Twin-Turbocharger" isn't already part of the string
        if (!engineValue.includes('Twin-Turbocharger')) {
            engineValue = engineValue.replace(/\bTurbo\b(?!charger)/g, 'Turbocharger');
        }

        // Cylinder conversion: Include potential hyphen or spaces
        let cylPattern = /(V|I)[- ]?(\d+)/;
        let cylMatch = engineValue.match(cylPattern);
        let cylinder = cylMatch ? `${cylMatch[2]} Cyl` : '';

        // Liter conversion
        let literPattern = /(\d+(\.\d+)?L)/;
        let literMatch = engineValue.match(literPattern);
        let liter = literMatch ? literMatch[1].replace('L', ' Liter') : '';

        // Check for 'Turbocharger' and 'Supercharger' presence
        let features = [];
        if (engineValue.includes('Twin-Turbocharger')) features.push('Twin-Turbocharger');
        else if (engineValue.includes('Turbocharger')) features.push('Turbocharger');
        if (engineValue.includes('Supercharger')) features.push('Supercharger');

        // Construct the new format
        let newFormat = [cylinder, liter, ...features].filter(Boolean).join(' ');

        // Append "+ Electric" at the end if it was originally present
        if (hasElectric) {
            newFormat += ' + Electric';
        }

        this.engineTarget.value = newFormat;
    }
}